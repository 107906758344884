<template>
  <b-col md="3">
    <b-card>
      <div class="d-flex justify-content-between">
          <div> <b>Filters</b> </div>
          <div
              :class="{'collapsed': !visible}"
              style="cursor:pointer"
              :aria-expanded="visible ? 'true' : 'false'"
              
              role="tab"
              data-toggle="collapse"
              @click="updateVisible(!visible)"
          >                                 
            <img :src="require('@/assets/images/icons/filter.png')" alt="filtro" height="26px">                                 
          </div>
      </div>  
        <hr v-if="visible">                      
        <b-collapse  v-model="visible" :accordion="accordion" role="tabpanel" >
          <b-card-body>
            <div class="d-flex justify-content-between mb-2">
              <div>

              <b>
                Sort products by:
              </b><br>
              <b-dropdown
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :text="sortBy.text"
                right
                variant="outline-primary"
                class="mt-1"
              >
                <b-dropdown-item 
                    v-for="sortOption in sortByOptions"
                    :key="sortOption.value"                    
                    @click="sortByOption(sortOption)"
                >
                  {{ sortOption.text }}
                </b-dropdown-item>
              </b-dropdown>                              
              </div>
            </div>
            <b>Filter on product list</b>
            <b-input-group class="mb-2 mt-1">             
            <b-form-input placeholder="Search products" v-model="filter.query" />              
            </b-input-group>
            <b>Get products by category</b>
          
          
            <app-collapse>
              <app-collapse-item :title="category.text" v-for="(category, index) in categories.withSubCategories" :key="index" :isVisible="collapseOpen( category)">
                <template v-slot:header>
                  <div class="d-flex justify-content-between">
                    <span class="mr-1"> <feather-icon icon="ArrowDownCircleIcon" size="16" /></span>
                    <span>{{category.text}}</span>
                  </div>                  
                </template>
                <b-form-radio-group
                  name="cats"
                  class="categories-radio-group"
                  stacked                                                                                     
                  :options="category.subcategory" 
                  v-model="selectedCategory.idSubCategory"
                  v-if="selectedCategory"
                  @change="getProductsBySubCategory"
                  :disabled="isLoadingProducts"
                />                
              </app-collapse-item>                            
            </app-collapse>    
            <hr v-if="categories.onlyCategories.length">    
            <div v-if="categories.onlyCategories.length">
              <div class="d-flex justify-content-between">
                <!-- <span class="ml-1"><feather-icon icon="ArrowDownCircleIcon" size="16" /></span>                 -->
                <span></span>
                <feather-icon icon="ChevronDownIcon" size="16" class="mr-1"/>
              </div>
              <b-form-radio-group
                name="cats"
                class="categories-radio-group ml-1"
                stacked                                                                                     
                :options="categories.onlyCategories" 
                v-model="selectedCategory.idCategory"
                v-if="selectedCategory"
                @change="getProductsByCategory"
                :disabled="isLoadingProducts"
              /> 
            </div>
            </b-card-body>
        </b-collapse>
    </b-card>
  </b-col>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
//seeds data
import { toJson } from '@/helpers/helpers'
import { itemViewOptions, sortByOptions,  } from "@/data/data"
import Ripple from 'vue-ripple-directive'

import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  directives: {
    Ripple,
  },
  components:{
     AppCollapse,
    AppCollapseItem,
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    sortBy: {
      type: Object,
      required: true,
    },     
  },  
  data() {
    return {
      visible: true,      
      imgUrl: process.env.VUE_APP_BASE_URL_SERVER,
      itemViewOptions, sortByOptions,       
      itemView:'grid-view',
    }
  },
  computed:{
    ...mapState('appConfig',['layout']),
    ...mapState('start',['categories','selectedCategory','selectedSubCategory', 'isLoadingCategories']),
    ...mapState('products',['isLoadingProducts','filter']),

    colorFilter(){
      return this.layout.skin === 'dark' ? 'title-filter-dark' : 'title-filter-light'
    },
    accordion() {
      return this.$parent.accordion ? `accordion-${this.$parent.collapseID}` : null
    },
    categoriesData(){
      const cats = []
        this.categories.defaults.forEach(category => {
          cats.push({
            action:'get-products-by-category',
            text: category.name,
            value: category.id,
        })
      })
      return cats
    }    
  },
  methods:{    
    ...mapActions('products',['fetchProducts','fetchProductsBySubCategory','fetchProductsByCategories','fetchPackages','fetchEvents']),
    ...mapMutations('start',['setSelectedCategory','setSelectedSubCategory']),
    ...mapMutations('products',['setProducts','setLoadingProducts']),
    async getProductsBySubCategory(idSubCategory){
      let products = []      
      const { withSubCategories, hotels, services } = this.categories
      const myCategories =  [...withSubCategories, ...hotels, ...services ]
      const categoria = toJson(myCategories.find(category => category.subcategory.find(subCat => subCat.value === idSubCategory )) ) //category para la peticion     
      categoria.idSubCategory = idSubCategory
      categoria.idCategory = null
      
      const { action } = categoria
      this.setLoadingProducts( true)                 
      
      if( action == 'get-products-by-sub-category'){        
        products = await this.fetchProductsBySubCategory( categoria )         
      }        
      this.setLoadingProducts(false)                 
      this.setSelectedCategory( categoria )        
      this.setProducts( products )
      this.$emit('set-current-page')
    },

    async getProductsByCategory( idCategory ){  
      let products = []      
      const { withSubCategories, hotels, services, onlyCategories} = this.categories
      const myCategories =  [...withSubCategories, ...hotels, ...onlyCategories, ...services ]
      const categoria = toJson(myCategories.find(category => category.value === idCategory  )) //category para la peticion     
      categoria.idSubCategory = null
      categoria.idCategory = idCategory
      
      const { action } = categoria
      this.setLoadingProducts( true)                 
      
      if( action == 'get-products-by-category'){        
        products = await this.fetchProductsByCategories( categoria )         
      }        
      
      this.setLoadingProducts(false)                         
      this.setSelectedCategory(categoria)            
      this.setProducts( products )
      this.$emit('set-current-page')                 
    },     
    updateVisible(val = true) {
      this.visible = val
      this.$emit('visible', val)
    },
    sortByOption(option){      
      this.$emit("sort-by-option", option)   
    },    
    collapseOpen( category){      
      const idSubCategory = this.selectedCategory.idSubCategory      
      if( idSubCategory ){     //si encuentra registro regresará un true    
        const cat = category.subcategory.find(subCat => subCat.value === idSubCategory )
        return cat ? true : false
      }
      if(!idSubCategory){
        return false
      }      
    }
}
}
</script>

<style lang="scss" scoped>
.categories-radio-group,
.brands-radio-group,
.price-range-defined-radio-group {
    ::v-deep > .custom-control {
    margin-bottom: 0.75rem;
  }
}
</style>
