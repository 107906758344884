<template>
  <b-col md="9" >
    <main class="grid" v-if="filteredProducts.length">
      <article v-for="(product, index) in productos" :key="index">
        <img :src="imgUrl + product.coverImage" :alt="product.name" @error="imgAlternativo" height="250" width="100%" style="cursor: pointer" @click="showProduct(product)">
        <div class="price-product"> Starting at <span> ${{product.detail[0].saleprice}} USD</span> </div>
        <div class="text">
          <h2 class="name-product" style="cursor: pointer" @click="showProduct(product)">{{product.name}}</h2>
          <SingleDetail :singleDetail="product.detail[0]" :isBestSeller="false" :product="product" v-if="product.detail.length == 1 "/>
          <ProductDetails :productDetails="product.detail" :isBestSeller="false" :product="product" v-else/>

          <div class="d-flex justify-content-between mt-2">
            <div>
              <b v-if="product.total !=='0.00'" class="total-product"> Total: $ {{product.total}}  USD</b>              
            </div>
            
            <div>
              <b-button variant="primary"  @click="handlerAddToCart(product)" size="sm">            
                <feather-icon icon="ShoppingCartIcon" size="18" /> 
              </b-button>
            </div>
          </div> 
        </div>
      </article>    
    </main>  
    <div class="row" v-else>
      <div class="col-md-12">
        <b-alert show variant="danger"  class="mt-2">
          <div class="alert-body text-center"> 😣 <span>There are no products founded</span></div>
        </b-alert>
      </div>
    </div> 
    <div class="text-center mt-2" v-if="filteredProducts.length > 0">
      <ProductsPagination :totalRows="filteredProducts.length" :filters="filters" v-if="filteredProducts.length > 8"/>
    </div>   
  </b-col>    
</template>

<script>
import { mapState, mapActions , mapMutations, mapGetters} from 'vuex'

import { market } from '@/modules/shop/mixins/market'
import { toJson } from '@/helpers/helpers'
import ProductsPagination from '@/modules/shop/components/experiences/ProductsPagination'
import ProductDetails from '@/modules/shop/components/experiences/ProductDetails'
import SingleDetail from '@/modules/shop/components/experiences/SingleDetail'

export default {
  mixins: [ market  ],
  props:{
    filters: {
      type: Object,
      required: true,
    },
  },
  components: {
    SingleDetail,
    ProductDetails,
    ProductsPagination,
  },
 
  data(){
    return {        
      imgUrl: process.env.VUE_APP_BASE_URL_SERVER,               
    }
  },        
  computed:{  
    ...mapState('shop',['cart']),    
    ...mapState('products',['filter','isloadingProducts']),    
    ...mapGetters('products',['filteredProducts']), 
    productos(){
      const items = this.filteredProducts
      return items.slice(
        (this.filters.currentPage - 1) * this.filters.perPage,
        this.filters.currentPage * this.filters.perPage
      )
    },
    totalRows() {
      return this.filteredProducts.length
    }    
  },
  methods:{
    ...mapActions('shop', ['fetchBlackoutDatesProduct']),
    ...mapMutations('shop',['addProductInCart','calculateTotalBreakdown']),    
    ...mapMutations('products',['resetQtyProductDetail']),

    async handlerAddToCart(product){
      let item = toJson({...product})//spread
      const productsAddCart = this.generateProductsToAddCart(item) // desde el mixin
      //ciclo asyncrono para ir añadiendo al cart
      productsAddCart.forEach( async productCart => {          
        const { qty } = productCart.detailSelected //tomo su cantidad
        const productFormatted = this.formatProductBeforeAddingToCart(productCart) //desde el mixin, formateo el item
        const productIsRepeated = this.existsInCart( productFormatted )
        if( !productIsRepeated ){ // si producto no se repite         
          this.addProductInCart(productFormatted) // agrego el producto formateado
          await this.setConfigBlackoutDatesProduct(productFormatted, qty)  // desde el mixin market 
        } else {
          await this.addOneMoreQty(productFormatted)
        }
      })  
      
      this.resetQtyProductDetail( {idProduct:product.id, isBestSeller: false} ) // reseteo los qty detail del producto         
      // console.log(this.$refs.myCart)
      // this.$root.$emit("bv::dropdown::shown", "myCart")                
    },
     
    async addOneMoreQty(product){
      const { modelType, detailId, qty }  = product   
      const prodInCart = this.cart.find( product => product.detailId === detailId && product.modelType === modelType )
      prodInCart.qty = prodInCart.qty + qty//aumento la cantidad
      this.calculateTotalBreakdown()
      await this.qtyHasChanged(prodInCart) // desde el mixim market
    },
    showProduct(product){      
      this.$router.push({ name: 'sales-product-details', params: { slug: 'products-'+ product.id } })
    },
    imgAlternativo( event ){
      event.target.src = require('@/assets/images/default.jpg')
    },
          
  }
}
</script>


<style lang="scss" scoped>

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  grid-gap: 20px;
  align-items: stretch;
}

.grid > article {
  border: 1px solid #DDDDDD;
  // box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.3);
  background: #fff;
  position: relative;
}

.grid > article img {
  max-width: 100%;
}

.grid .text {
  padding: 20px;
}
.price-product{
  position: absolute;
  top: 20px;
  background-color: #F56960;  
  font-size: .8rem;
  color: #ffffff;
  padding: 8px 16px;

}
.price-product span{
  font-size: 1.1rem;  
  font-weight: 700;
  color: #ffffff;

}
.name-product{
  font-size: 1rem;
  font-weight: 700;
  margin-block-end: .8rem;
  color: black;
}

.total-product {
  color: rgb(255, 254, 254);
  font-size: .9rem;
  background-color: #F56960;
  padding: 0.3rem 0.6rem;
  text-align: center;
  font-weight: 500;
}
</style>