export const appVersion = { version: '2.4.5', date: '2022-01-18' }


export const codeHotels = [
    { idHotel: 1, key: 'TFB', code: 1105 },
    { idHotel: 2, key: 'TFO', code: 1100 },
    { idHotel: 3, key: 'TFD', code: null }
]

export const itemViewOptions = [
    { icon: 'GridIcon', value: 'grid-view' },
    { icon: 'ListIcon', value: 'list-view' },
]
export const sortByOptions = [
    { text: 'Alphabetical Order', value: 'alfabetico' },
    { text: 'Ascending Price', value: 'price-asc' },
    { text: 'Descending Price', value: 'price-desc' },
]
export const filterOptions = {
    priceRangeDefined: [
        { text: 'All', value: 'all' },
        { text: '<= $10', value: '<=10' },
        { text: '$10 - $100', value: '10-100' },
        { text: '$100 - $500', value: '100-500' },
        { text: '>= $500', value: '>=500' },
    ]
}

export const filters = {
    q: '',
    priceRangeDefined: 'all',
    priceRange: [0, 100],
    categories: [],
    hotel: '',
    category: '',
    service: '',
    ratings: null,
    currentPage: 1,
    perPage: 8,
}

export const categoryHotels = [
    { text: 'The Fives Downtown', value: 9 },
    { text: 'The Fives Oceanfront', value: 10 },
    { text: 'The Fives Beach', value: 11 }
]

export const services = [
    { value: 1, text: 'Eventos', action: 'get-events' },
    { value: 2, text: 'Paquetes', action: 'get-packages' },
]

export const vendorOptions = [
    { value: 'CONTACTCENTER', title: 'Contact center' },
]

export const rateOptions = [
    { value: '', title: 'Todos' },
    { value: 'ASIRACK', title: 'ASI' }
]
export const languageOptions = [
    { value: 'es', title: 'Español' },
    { value: 'en', title: 'Ingles' },
]

export const adultOptions = [
    { value: 1, title: 1 },
    { value: 2, title: 2 },
    { value: 3, title: 3 },
    { value: 4, title: 4 },
    { value: 5, title: 5 },
    { value: 6, title: 6 },
    { value: 7, title: 7 },
    { value: 8, title: 8 },
]

export const childrenOptions = [
    { value: 0, text: 0 },
    { value: 1, text: 1 },
    { value: 2, text: 2 },
    { value: 3, text: 3 },
    { value: 4, text: 4 },
    { value: 5, text: 5 },
    { value: 6, text: 6 },
    { value: 7, text: 7 },
    { value: 8, text: 8 },
    { value: 9, text: 9 },
]
export const ageChildrenOptions = [
    { value: 1, text: 1 },
    { value: 2, text: 2 },
    { value: 3, text: 3 },
    { value: 4, text: 4 },
    { value: 5, text: 5 },
    { value: 6, text: 6 },
    { value: 7, text: 7 },
    { value: 8, text: 8 },
    { value: 9, text: 9 },
    { value: 10, text: 10 },
    { value: 11, text: 11 },
    { value: 12, text: 12 },
    { value: 13, text: 13 },
    { value: 14, text: 14 },
    { value: 15, text: 15 },
    { value: 16, text: 16 },
    { value: 17, text: 17 },
]

export const searchBookingData = {
    vendor: '',
    language: null,
    name: '',
    rangeDate: 0,
    dateIn: '',
    dateOut: '',
    nights: 0,
    hotel: '',
    room: null,
    adults: 1,
    numberChildren: 0,
    ninos: [],
    promoCode: null,
    promoCodeManual: '',
    userAuthorizeRate: { isInvalid: true, idUser: null, username: '', code: '' }
}

export const namesPrefix = [
    { value: 'Mr', text: 'Mr' },
    { value: 'Ms', text: 'Ms' },
    { value: 'Mrs', text: 'Mrs' },
    { value: 'Miss', text: 'Miss' },
]

export const verticalMenuItems = [{
    id: 1,
    title: 'Experiencias',
    icon: 'ShoppingCartIcon',
    // tag: '2',
    tagVariant: 'light-primary',
    children: [{
        title: 'Experiencias',
        route: 'sales'
    },
    {
        title: 'Estancias',
        route: 'booking'
    }
    ],
},
]

export const verticalMenuItems2 = [{
    id: 1,
    title: 'Experiencias :)',
    icon: 'ShoppingCartIcon',
    // tag: '2',
    tagVariant: 'light-primary',
    children: [{
        title: 'Experiencias',
        route: 'sales'
    }],
},
]


