<template>
    <b-dropdown variant="link" toggle-class="p-0" no-caret right menu-class="w-100" >
        <template #button-content>
            <div class="picker-detail">
                <div class="d-flex justify-content-between" >
                    <div>
                        <span class="items-selected">Choose options</span>                        
                        <span class="items-selected-count">{{productDetails.reduce((sum, li) => sum + li.qty, 0)}} productos selected </span>
                    </div>
                    <div class="arrow-picker">
                        <feather-icon icon="ChevronDownIcon" size="20" /> 
                    </div>
                </div>            
            </div>
        </template>
        <b-dropdown-form>
            <div  v-for="(detail) in productDetails" :key="detail.id">
                <div>
                    <span class="name-detail">{{detail.detaildisplay || detail.detailDesc }}</span>
                </div>
                <div class="d-flex justify-content-between">
                    <div class="price-detail">
                        <span variant="warning" class="badge-price"> ${{detail.saleprice}} USD</span>  
                    </div>
                    <div>
                        <button class="minus-qty" type="button" :disabled="detail.qty == 0" @click="handlerQty('minus', detail)"> - </button>
                        <span class="qty-number">{{ detail.qty }}</span>
                        <button class="plus-qty" type="button" @click="handlerQty('plus', detail)"> + </button>
                    </div>
                </div>                    
            </div>                    
        </b-dropdown-form>
    </b-dropdown>
</template>

<script>
import { market } from '@/modules/shop/mixins/market'
export default {
  mixins: [ market  ],
  props:{       
    productDetails: {
      type: Array,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
    isSingle:{
      type: Boolean,
      default: false,
    },
    isBestSeller:{
      type: Boolean,
      default: false,
    }
  },
  methods:{
    
    handlerQty(operator, detail){
      if(operator === 'plus'){
        detail.qty += 1
      }
      if(operator === 'minus'){
        detail.qty -= 1
      }
      if(this.isSingle){
        this.handlerQtyProductSingle(this.product, detail, this.isBestSeller)
      }

      if( !this.isSingle ){
        this.handlerQtyProduct(this.product, detail, this.isBestSeller)              
      }    
    }
  }
}
</script>

<style scoped>
.picker-detail{
  border: 2px solid #DDDDDD;
  border-radius: 8px;
  cursor: pointer;
  padding: .5rem 1rem;  
}

.picker-detail:active, .picker-detail:focus {
  border: 2px solid black;    
  color: black;

}
.items-selected {
  font-size: 11px;
  font-weight: 800;
  margin-block-end: .5rem;
  display: flex;
  align-items: left;
  color: black;

}
.items-selected-count{
  font-size: 12px;
  font-weight: 400;
  display: flex;
  align-items: left;
  color: black;
}
.arrow-picker{
  display: flex;
  align-items: center;
}
.dropdown.b-dropdown{
  width: 100%;
}

.minus-qty{
  width:30px;
  height:30px;
  border-radius: 50%;
  border: 1px solid #b4b0b0;
  background-color: transparent;
  margin-inline-end: .5rem; 
  color: black;
  font-size: 1.5rem;
}
.minus-qty:disabled{
  cursor: not-allowed;
}
.plus-qty{
  width:30px;
  height:30px;
  border-radius: 50%;
  border: 1px solid #b4b0b0;
  background-color: transparent;    
  color: black;
  font-size: 1.5rem;
  
}
.minus-qty:hover, .plus-qty:hover{
  border: 1px solid #030303;

}
.qty-number{
  font-weight: 600;
  margin-inline-end: .5rem;
  color: black;
}
.name-detail{
  font-size: .9rem;
  font-weight: 600;
  color: black;
}
.price-detail{
  font-size: .8rem;
  font-weight: 500;
  color: black;
  display: flex;
  align-items: center;
}
</style>