<template>
    <div>    
        <Header/>              
          <b-row style="margin-top:5rem" class="p-1">                
            <SkeletonFiltroSidebar  v-if="isLoadingSidebar "/>
            <FiltroSidebar :filters="filters" :sortBy="sortBy" @set-current-page="setCurrentPage" @sort-by-option="sortByOption"  v-if="!isLoadingSidebar"/>
            <SkeletonProducts v-if="isLoadingProducts" />                                                                           
            <Products :filters="filters" v-if="!isLoadingProducts" />                         
          
          </b-row>    
        <!-- <Footer/>     -->
    </div>
</template>
<script>
import { mapState, mapActions,  mapMutations } from 'vuex'
import Header from '@/modules/shop/components/home/Header'
import Footer from '@/modules/shop/components/home/Footer'
import Products from '@/modules/shop/components/experiences/Products'
import FiltroSidebar from '@/modules/shop/components/experiences/FiltroSidebar'
//skeletons
import SkeletonFiltroSidebar from '@/modules/shop/components/experiences/skeletons/SkeletonFiltroSidebar'
import SkeletonProducts from '@/modules/shop/components/experiences/skeletons/SkeletonProducts'

import { toJson, overWriteAxiosHeaders } from '@/helpers/helpers'
import { market } from '@/modules/shop/mixins/market'
import { filters } from "@/data/data"

export default {
    mixins: [market],    
    components:{       
      Header,
      FiltroSidebar,
      Products,
      Footer, 
      SkeletonFiltroSidebar,
      SkeletonProducts ,
          
    },
    data(){
      return {
        isLoadingSidebar: false,                                        
        filters,
        sortBy: { text: "Alphabetical Order", value: "alfabetico" },
        itemView:'grid-view',
      }
    },
    async created(){
      this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true)
      await this.init()       
    },
    
    computed:{
      ...mapState('shop',['cart','customerCurrency']),           
      ...mapState('start',['selectedCategory']),           
      ...mapState('auth',['queryParams','user', 'isLogged']),               
      ...mapState('start',['currencies', 'categories', 'hotels', 'languages',]),
      ...mapState('products',['bestSellers','isLoadingProducts','products']),                                    
    },
    methods:{
      ...mapActions('shop',['getInitialContentMarket']),   
      ...mapActions('products',['getInitialContentLanding','fetchProductsBySubCategory','fetchProductsByCategories', 'fetchBestSellerProducts']),    
      ...mapMutations('products',['setBestSellersProducts','setProducts','setLoadingProducts','setSortBy']),         
      ...mapMutations('auth',['setDataQueryParams']), 
      ...mapMutations('shop',['setCurrencyCustomer']),
      ...mapMutations('start',['setCategories','setCurrencies','setHotels','setLanguages','setSelectedCategory']),     
      async init(){
          
          this.resetStateHome()
          this.setInitialParams() //ejecutado desde le mixin
          
          if( this.queryParams ){
              overWriteAxiosHeaders( this.queryParams ) //sobreescribo axios
              if( !this.isLogged ){        
                await this.loginApp()                    
              }
              if( this.isLogged ){
                this.isLoadingSidebar = true
                this.setLoadingProducts(true)
                await this.appIsLogged() //pedir datos iniciales si la app está conectada (categorias, currencies, hotels, languages)                               
                this.isLoadingSidebar = false                
                await this.getInitialProductsByCategory() //pedir productos, de la primera categoría existente
                this.setLoadingProducts(false)                
              }
          }

          if( !this.queryParams ){
            this.$router.push({name: 'not-found' })
          }            
      }, 
      async appIsLogged(){
          const initialStatesAreEmpty = this.initialStatesAreEmpty()              
          if( initialStatesAreEmpty ){
              const { categories, currencies, hotels, languages } = await this.getInitialContentMarket() //TODO: pedir datos si no hay categorias, currencies, hotels o languages
              this.setCategories( categories ) 
              this.setCurrencies(currencies)
              this.setHotels(hotels)
              this.setLanguages(languages) 
              this.putCurrencyCustomer() //poner la moneda para el cliente 
          }
          document.title = `${this.user.name} ${this.user.lastname}`  
      },
      initialStatesAreEmpty(){  
          const {  withSubCategories } = this.categories   
          if( !this.currencies.length || !this.hotels.length || !this.languages.length || !withSubCategories.length ){
              return true
          }
          return false
      },
      putCurrencyCustomer() {
          const divisa = toJson( this.currencies.find( currency => currency.code === 'USD') )
          if (!this.customerCurrency) {
              this.setCurrencyCustomer(divisa)
          }
      },
      async getBestSellerProducts(){
          if( !this.bestSellers.length ){
              const products = await this.fetchBestSellerProducts()
              this.setBestSellersProducts(products) 
          }
      },
      async getInitialProductsByCategory(){
        if(!this.selectedCategory ){
          const { withSubCategories, onlyCategories } = this.categories
          if(withSubCategories.length > 0){

            const category = withSubCategories[0]
            category.idSubCategory = category.subcategory[0].value
            category.idCategory = null
            this.setSelectedCategory( withSubCategories[0] )             
          }
          if(onlyCategories.length){
            const category = onlyCategories[0]
            category.idSubCategory = null
            category.idCategory = category.value
            this.setSelectedCategory( onlyCategories[0] ) 
          }
        } 
        if(!this.products.length && this.selectedCategory){
          if(this.selectedCategory.idSubCategory){
            const products = await this.fetchProductsBySubCategory(this.selectedCategory) 
            this.setProducts( products )                                    
          }
          if(this.selectedCategory.idCategory){
            const products = await this.fetchProductsByCategories(this.selectedCategory) 
            this.setProducts( products )                                    
          }
        }                                              
      },
          
    chageView(viewType){      
      this.$emit("change-view", viewType)   
    },
    sortByOption(option) {
      this.sortBy = option
      this.setSortBy(JSON.parse(JSON.stringify(this.sortBy)))
    },
    
    setCurrentPage() {
      this.filters.currentPage = 1
    },      
                  
  }
}
</script>

<style lang="scss" scoped>

@import "@/assets/scss/best-sellers.scss";

.categories-radio-group{
    ::v-deep > .custom-control {
    margin-block-end: 0.75rem;
  }
}
</style>

