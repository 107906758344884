<template>
    <header class="my-header">
        <!-- <a href="" class="logo"><img :src="require('@/assets/images/logo/logo-the-fives.png')" alt="" height="32"> </a> -->
        <input class="my-menu-btn" type="checkbox" id="my-menu-btn" />
        <label class="my-menu-icon" for="my-menu-btn"><span class="navicon"></span></label>
        <cart-dropdown class="my-cart" v-if="$route.name !=='order-summary'"/>                                                     
        <ul class="my-menu">                          
          <li><router-link :to="{ name: 'home' }" class="mr-1" :class="$route.name === 'home' ? 'active-item' : '' ">Home</router-link></li>
          <li><router-link :to="{ name: 'sales' }" class="mr-1" :class="$route.name === 'sales' ? 'active-item' : '' ">Experiences</router-link></li>
          <li><router-link :to="{ name: 'order-summary' }" class="mr-1" :class="$route.name === 'order-summary' ? 'active-item' : '' ">Order summary</router-link></li>
          <li><router-link :to="{ name: 'payment' }" class="mr-1" :class="$route.name === 'payment' ? 'active-item' : '' ">Checkout</router-link></li>
        </ul>
    </header>
</template>

<script>
import CartDropdown from '@/modules/shop/components/cart/CartDropdown'

export default {
    components:{    
      CartDropdown
    },
}
</script>

<style lang="scss" scoped>

a {
  color: #000;
}

/* header */

.my-header {
  background-color: #fff;
  box-shadow: 1px 1px 4px 0 rgba(0,0,0,.1);
  position: fixed;
  width: 100%;
  z-index: 3;
  top: 0;
}

.my-header ul {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
  background-color: #fff;
  z-index: 2;

}

.my-header li a {
  display: block;
  padding: 20px 20px;
  border-right: 1px solid #f4f4f4;
  text-decoration: none;

}


.my-header .logo {
  display: block;
  float: left;
  font-size: 2em;
  padding: 10px 20px;
  text-decoration: none;
}

.my-header .my-cart {
  display: block;
  float: right;
  font-size: 2em;
  padding-top: 10px;
  text-decoration: none;
}

/* my-menu */

.my-header .my-menu {
  clear: both;
  max-height: 0;
  transition: max-height .2s ease-out;
}

/* my-menu icon */

.my-header .my-menu-icon {
  cursor: pointer;
  display: inline-block;
  float: right;
  padding: 28px 20px;
  position: relative;
  user-select: none;
}

.my-header .my-menu-icon .navicon {
  background: #333;
  display: block;
  height: 2px;
  position: relative;
  transition: background .2s ease-out;
  width: 18px;
}

.my-header .my-menu-icon .navicon:before,
.my-header .my-menu-icon .navicon:after {
  background: #333;
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  transition: all .2s ease-out;
  width: 100%;
}

.my-header .my-menu-icon .navicon:before {
  top: 5px;
}

.my-header .my-menu-icon .navicon:after {
  top: -5px;
}

/* my-menu btn */

.my-header .my-menu-btn {
  display: none;
}

.my-header .my-menu-btn:checked ~ .my-menu {
  max-height: 240px;
}

.my-header .my-menu-btn:checked ~ .my-menu-icon .navicon {
  background: transparent;
}

.my-header .my-menu-btn:checked ~ .my-menu-icon .navicon:before {
  transform: rotate(-45deg);
}

.my-header .my-menu-btn:checked ~ .my-menu-icon .navicon:after {
  transform: rotate(45deg);
}

.my-header .my-menu-btn:checked ~ .my-menu-icon:not(.steps) .navicon:before,
.my-header .my-menu-btn:checked ~ .my-menu-icon:not(.steps) .navicon:after {
  top: 0;
}

/* 48em = 768px */

@media (min-width: 48em) {
  .my-header li {
    float: left;
  }
  .my-header li a {
    padding: 20px 30px;
  }
  .my-header .my-menu {
    clear: none;
    float: right;
    max-height: none;
  }
  .my-header .my-menu-icon {
    display: none;
  }
}

.active-item { 
  color: rgb(255, 255, 255);
  font-weight: bold;
  background: #333366 ;
}
</style>