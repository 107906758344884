<template>
  <div class="single-detail">
    <div>
      <span class="name-detail">{{singleDetail.detaildisplay || singleDetail.detailDesc }}</span>
    </div>
    <div class="d-flex justify-content-between">
      <div class="price-detail">
        <span variant="warning" class="badge-price"> ${{singleDetail.saleprice}} USD</span>  
      </div>
      <div>
        <button class="minus-qty" type="button" :disabled="singleDetail.qty == 0" @click="handlerQty('minus', singleDetail)"> - </button>
        <span class="qty-number">{{ singleDetail.qty }}</span>
        <button class="plus-qty" type="button" @click="handlerQty('plus')"> + </button>
      </div>
    </div>                                  
  </div> 
</template>
<script>
import { market } from '@/modules/shop/mixins/market'

export default {
  mixins: [ market ],
  props:{       
    singleDetail: {
      type: Object,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
    isSingle:{
      type: Boolean,
      default: false,
    },
    isBestSeller:{
      type: Boolean,
      default: false,
    }
  },
  methods:{   
    handlerQty(operator){
      if(operator === 'plus'){
        this.singleDetail.qty += 1
      }

      if(operator === 'minus'){
        this.singleDetail.qty -= 1
      }

      if(this.isSingle){        
        this.handlerQtyProductSingle(this.product, this.singleDetail, this.isBestSeller)
      }

      if( !this.isSingle ){
        this.handlerQtyProduct(this.product, this.singleDetail, this.isBestSeller)              
      }
    }
  }
}
</script>

<style scoped>
.single-detail{
  border: 1px solid #DDDDDD;
  border-radius: 8px;
  padding: .6rem;
  
}
.arrow-picker{
  display: flex;
  align-items: center;
}
.dropdown.b-dropdown{
  width: 100%;
}

.minus-qty{
  width:30px;
  height:30px;
  border-radius: 50%;
  border: 1px solid #b4b0b0;
  background-color: transparent;
  margin-inline-end: .5rem; 
  color: black;
  font-size: 1.5rem;  
}
.minus-qty:disabled{
  cursor: not-allowed;
}
.plus-qty{
  width:30px;
  height:30px;
  border-radius: 50%;
  border: 1px solid #b4b0b0;
  background-color: transparent;    
  color: black;
  font-size: 1.5rem;
  
}
.minus-qty:hover, .plus-qty:hover{
  border: 1px solid #030303;

}
.qty-number{
  font-weight: 600;
  margin-inline-end: .5rem;
  color: black;
}
.name-detail{
  font-size: .9rem;
  font-weight: 600;
  color: black;
}
.price-detail{
  font-size: .8rem;
  font-weight: 500;
  color: black;
  display: flex;
  align-items: center;
}
</style>